<template>
  <v-container class="mt-3 px-sm-10 px-3" fluid>
    <PageHeader
      header-text="Prescription Report"
    >
      <template #subheader>
        <div class="d-flex">
          <div class="flex-fill">
            Generate a report to view statistics related to prescriptions written.
          </div>
          <CustomDateRangePicker
            v-model="dateRanges"
            :format="showDateFormat"
            :max-date="maxDate"
            :required="true"
          />
        </div>
      </template>
    </PageHeader>
    <v-row class="mt-4">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          class="custom-button px-13 mr-2"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="reset"
        >
          Reset
        </v-btn>
        <v-btn
          class="custom-button custom-button--blue px-13"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="submit"
        >
          Generate Report
        </v-btn>
      </v-col>
    </v-row>
    <v-container
      v-show="showReport"
      fluid
      class="mt-8"
      :style="{
        height: dynamicHeight ? dynamicHeight + 'px' : 'auto',
      }"
    >
      <v-row class="grid-stack grid-stack-4" style="z-index: 7">
        <div
          v-for="reportWidget in reportWidgets"
          :id="'report-widget-' + reportWidget.id"
          :key="'report-widget-' + reportWidget.id"
          class="grid-stack-item ui-draggable-disabled ui-resizable-disabled"
          :gs-x="reportWidget.xPosition"
          :gs-y="reportWidget.yPosition"
          :gs-w="reportWidget.width"
          :gs-h="reportWidget.height"
        >
          <div class="grid-stack-item-content">
            <component
              :is="reportWidget.renderComponentName"
              class="item-component"
              :config="reportWidget"
              :data="reportWidget.data"
              vertical
            />
          </div>
        </div>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";
import PageHeader from "@/sharedComponents/PageHeader";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import LineChart from "@/sharedComponents/charts/LineChart";
import CustomDateRangePicker from "@/sharedComponents/CustomDateRangePicker";
import CountWidget from "@/sharedComponents/widgets/CountWidget";
import MultipleBarChartWidget from "@/sharedComponents/widgets/MultipleBarChartWidget";
import PrescriptionHistoryWidget from "@/views/Plugins/PrescriptionData/PrescriptionHistoryWidget";
import {GridStack} from "gridstack";

export default {
  name: "PrescriptionReportView",
  metaInfo: {
    title: 'Prescription Report'
  },
  components: {
    CustomDateRangePicker,
    LineChart,
    CustomDropdown,
    PageHeader,
    CountWidget,
    MultipleBarChartWidget,
    PrescriptionHistoryWidget,
  },
  mixins: [datesMixin],
  data: () => ({
    bannerSources: [],
    reportPayload: {
      source: '',
    },
    dateRanges: {
      start: new Date(),
      end: new Date(),
    },
    maxDate: new Date(),
    showReport: null,
    showDateFormat: "MM/DD/YYYY",
    grid: null,
    gridConfig: {
      alwaysShowResizeHandle: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ),
      resizable: true,
      column: 4,
      cellHeight: "140px", // minimum size when height of block equals 1. Should be high as much as possible, small value (1px) may cause performance issues
      acceptWidgets: false, // Disable dragging outside widgets
    },
    dynamicHeight: 0,
    reportWidgets: {
      totalNBRx: {
        id: 1,
        xPosition: 0,
        yPosition: 0,
        name: "New Prescriptions (NBRx)",
        height: 1,
        width: 1,
        componentName: "app.widget.countwidget",
        renderComponentName: "CountWidget",
        data: {},
        component: "NBRxCountWidget",
        configuration: {
          color: "#2B84EB",
        }
      },
      totalTRx: {
        id: 2,
        xPosition: 1,
        yPosition: 0,
        name: "Recurring Prescriptions (TRx)",
        height: 1,
        width: 1,
        componentName: "app.widget.countwidget",
        renderComponentName: "CountWidget",
        data: {},
        component: "TRxCountWidget",
        configuration: {
          color: "#FD6868",
        },
      },
      totalPrescribers: {
        id: 3,
        xPosition: 2,
        yPosition: 0,
        name: "Total Prescribers within Date Range",
        height: 1,
        width: 1,
        componentName: "app.widget.countwidget",
        renderComponentName: "CountWidget",
        data: {},
        component: "TotalPrescribersCountWidget",
        configuration: {
          color: "#66CC66",
        },
      },
      totalNonPrescribing: {
        id: 4,
        xPosition: 3,
        yPosition: 0,
        name: "Non-Prescribers since Cut Off Date",
        height: 1,
        width: 1,
        componentName: "app.widget.countwidget",
        renderComponentName: "CountWidget",
        data: {},
        component: "NonPrescribersCountWidget",
        configuration: {
          color: '#881122',
        },
        nameIconTooltip: 'The cut-off date configured in the Prescription Data plugin determines when a contact is seen as a "non-prescriber".',
      },
      prescriptionHistoryLineChart: {
        id: 5,
        xPosition: 0,
        yPosition: 1,
        height: 3,
        width: 4,
        lineChartHeight: 380,
        componentName: "app.plugin.prescriptiondataplugin.widget.prescriptionhistorywidget",
        renderComponentName: "PrescriptionHistoryWidget",
        data: {},
        component: "PrescriptionHistoryWidget",
        name: 'Prescriptions over Time',
      },
      specialtyBarChart: {
        id: 6,
        xPosition: 0,
        yPosition: 4,
        height: 3,
        width: 4,
        chartHeight: 380,
        layout: {padding: 20},
        componentName: "app.widget.multiplebarchartwidget",
        renderComponentName: "MultipleBarChartWidget",
        data: {},
        component: "SpecialtyBarChartWidget",
        name: 'Top 10 Specialties',
      },
    },
  }),
  created() {
    this.dateRanges.start = (new Date()).setMonth((new Date()).getMonth() - 6);
  },
  methods: {
    initGrid() {
      this.grid = GridStack.init(this.gridConfig);
      this.grid.enableMove(false, false);
      this.grid.enableResize(false, false);
      window.grid = this.grid;

      setTimeout(this.calculateDynamicHeight.bind(this), 500);
    },
    calculateDynamicHeight() {
      // find lowest widget
      const domWidgets = document.querySelectorAll(".grid-stack-item");
      let bottomHeight = 0;
      for (const domWidget of domWidgets) {
        const bottomOfWidget = domWidget.offsetTop + domWidget.offsetHeight;
        if (bottomOfWidget > bottomHeight) {
          bottomHeight = bottomOfWidget;
        }
      }

      this.dynamicHeight = bottomHeight > 0 ? bottomHeight + 50 : 0;
    },
    async submit() {
      const reportFilters = {};

      if (this.dateRanges?.start && this.dateRanges?.end) {
        reportFilters.startDate = this.getSystemDateFormat(this.dateRanges.start);
        reportFilters.endDate = this.getSystemDateFormat(this.dateRanges.end);
      }

      this.$rest.plugins.prescriptionData.getReportData(reportFilters).then(result => {
        const data = result.data.data;

        this.initGrid();
        this.showReport = true;

        this.reportWidgets.totalNBRx.data = {count: (data.counts?.nbrx ?? 0)};
        this.reportWidgets.totalTRx.data = {count: (data.counts?.trx ?? 0)};
        this.reportWidgets.totalPrescribers.data = {count: (data.counts?.prescribers ?? 0)};
        this.reportWidgets.totalNonPrescribing.data = {count: (data.counts?.nonPrescribingContactsCutOff ?? 0)};
        this.reportWidgets.prescriptionHistoryLineChart.data = data.prescriptionHistory ?? {};

        this.reportWidgets.specialtyBarChart.data = {
          labels: (data.countsBySpecialty ?? []).map(data => data.specialty || 'Unknown'),
          datasets: [
            {
              label: 'NBRx',
              data: (data.countsBySpecialty ?? []).map(data => data.nbrx),
              datalabels: {
                align: 'end',
                anchor: 'end',
              },
            },
            {
              label: 'TRx',
              data: (data.countsBySpecialty ?? []).map(data => data.trx),
              datalabels: {
                align: 'end',
                anchor: 'end',
              },
            },
          ],
        };
      });
    },
    reset() {
      this.reportPayload = {};
      this.dateRanges = {
        start: (new Date()).setDate(1),
        end: new Date(),
      };
      this.showReport = false;
    },
  },
}
</script>

<style lang="scss" scoped>
@import url("../../../../node_modules/gridstack/dist/gridstack-extra.css");

.grid-stack {
  >.grid-stack-item {
    height: 160px;

    >.grid-stack-item-content {
      padding: 5px;
      height: 100%;
    }
  }
  .item-component {
    height: 100%;
  }
}
</style>
