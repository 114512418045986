<template>
  <AppWidgetBlock
    v-bind="{config}"
    @open-settings="$emit('open-settings', $event)"
    @delete-widget="$emit('delete-widget', $event)"
  >
    <div class="d-flex flex-grow-1">
      <div class="chart-container">
        <bar-chart
          style="width: 100%"
          :height="config.chartHeight || 180"
          :chart-data="chartData"
          :options="options"
        />
      </div>
    </div>
  </AppWidgetBlock>
</template>

<script>
import AppWidgetBlock from "@/views/Dashboards/Widgets/components/AppWidgetBlock";
import BarChart from "@/sharedComponents/charts/BarChart";

export default {
  name: "MultipleBarChartWidget",
  components: {
    AppWidgetBlock,
    BarChart
  },
  props: {
    config: {
      default: () => ({
        size: '2x1',
        name: 'Multiple Bar Chart'
      })
    },
    data: {
      type: Object,
      required: true,
    }
  },
  data(){
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: this.config.hideLegend
          ? {display: false}
          : {
            position: "bottom",
            labels: {
              usePointStyle: true,
            },
          },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            },
            stacked: false,
          }],
          yAxes: [{
            gridLines: {
              drawOnChartArea: true
            },
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
              callback: function(value) {
                return new Intl.NumberFormat().format(value);
              }
            },
            stacked: false,
          }],
        }
      }
    }
  },
  computed: {
    chartData() {
      return {
        labels: this.data?.labels || [],
        datasets: (this.data?.datasets || []).map((dataset, index) => ({
          backgroundColor: this.getColor(index),
          ...dataset
        })),
      }
    },
  },
  watch: {
  },
  methods: {
    getColor(index) {
      const colorArr = [
        "#2B84EB",
        "#FD6868",
        "#EF9F31",
        "#7CD9D6",
        "#66CC66",
        "#6699CC",
        "#CC6699",
        "#FFCC99",
        "#CC66CC",
        "#881122",
        "#CCCCCC",
      ];

      if (index > colorArr.length - 1) {
        return colorArr[colorArr.length - 1];
      }

      return colorArr[index];
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  max-width: 150px;
  word-wrap: break-word;
  white-space: pre-wrap;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0 !important;
}

.right {
  transform: rotate(-90deg);
}

.divider {
  max-height: calc(100% + 32px) !important;
  min-height: calc(100% + 32px) !important;
}

.chart-title {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
}

.chart-container {
  width: 100%;
}
</style>